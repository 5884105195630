<!--数据-->
<template>
    <div class="container data-box newBox">
        <div class="headerBox">
            <p>- TECHNOLOGY CLASSIFICATION -</p>
            <p class="headerText">技术分类</p>
        </div>



        <div class="centerSelect">
            <div class="leftSelectBox">
                <div class="SelectTopBox">
                    <div>固废来源</div>
                    <div class="blackStyle"></div>
                </div>
                <div class="SelectListBox">
                    <div class="SelectListItem" v-for="(item,index) in sources " :key="index">
                        <div class="gouImg" v-if="item.active">
                            <img src="../assets/img/gou.png" alt="">
                        </div>
                        <div class="itemText" :class="{active:item.active}" @click="clickCategory(item,index)">{{item.label}}</div>
                    </div>
                </div>
            </div>

            <div class="leftSelectBox">
                <div class="SelectTopBox">
                    <div>固废大类</div>
                    <div class="blackStyle"></div>
                </div>
                <div class="SelectListBox">
                    <div class="SelectListItem" v-for="(item,index) in wasteBigType " :key="index">
                        <div class="gouImg" v-if="item.active1">
                            <img src="../assets/img/gou.png" alt="">
                        </div>
                        <div class="itemText" :class="{active1:item.active1}" @click="clickbig(item,index)">{{item.label}}</div>
                    </div>
                </div>
            </div>

            <div class="leftSelectBox">
                <div class="SelectTopBox">
                    <div>固废小类</div>
                    <div class="blackStyle"></div>
                </div>
                <div class="SelectListBox">
                    <div class="SelectListItem" v-for="(item,index) in wasteSmallType " :key="index">
                        <div class="gouImg" v-if="item.active2">
                            <img src="../assets/img/gou.png" alt="">
                        </div>
                        <div class="itemText" :class="{active2:item.active2}" @click="smallCK(item,index)">{{item.label}}</div>
                    </div>
                </div>
            </div>

            <div class="leftSelectBox" style="margin-top: 2%;">
                <div class="SelectTopBox">
                    <div>资源化产品大类</div>
                    <div class="blackStyle"></div>
                </div>
                <div class="SelectListBox">
                    <div class="SelectListItem" v-for="(item,index) in productBig " :key="index">
                       <!-- <div class="gouImg">
                            <img src="../assets/img/gou.png" alt="">
                        </div> -->
                        <div class="itemText">{{item.label}}</div>
                    </div>
                </div>
            </div>

            <div class="leftSelectBox" style="margin-top: 2%;">
                <div class="SelectTopBox">
                    <div>资源化产品小类</div>
                    <div class="blackStyle"></div>
                </div>
                <div class="SelectListBox">
                    <div class="SelectListItem" v-for="(item,index) in 10 " :key="index">
                        <div class="gouImg">
                            <img src="../assets/img/gou.png" alt="">
                        </div>
                        <div class="itemText">生活垃圾</div>
                    </div>
                </div>
            </div>


            <div class="leftSelectBoxTWO" style="margin-top: 2%;">
                <div class="fuzzyQuerybOX">
                    <p>模糊查询</p>
                    <p class="cxStyle">选择以上条件或者直接输入相关字段进行模糊<br>查询</p>
                    <el-input placeholder="请输入内容" v-model="searchModel.searchValue" clearable>
                    </el-input>
                    <div class="CXResultBox" @click="selectData">查询结果 →</div>
                </div>
            </div>
        </div>

        <div class="emptyAndInquire">
            <div class="qKBox">
                <div class="qKImg">
                    <img src="../assets/img/qk.png" alt="">
                </div>
                <div class="qkTEXT">清空条件</div>
            </div>
        </div>

        <div class="emptyAndInquire">
            <div class="InquireBOX">
                <div class="qKImg">
                    <img src="../assets/img/cxb.png" alt="">
                </div>
                <div class="cXTEXT">查询结果</div>
            </div>
        </div>


        <div class="CXresult">
            <p class="EnglishText">- QUERY RESULTS -</p>
            <p class="resultText">查询结果</p>
        </div>

        <div class="resultListBox">
            <div class="resultListTop">
                <div class="boxStyle">技术类型</div>
                <div class="boxStyle">技术分类编码</div>
                <div class="boxStyle">说明</div>
                <div class="boxStyle">操作</div>
            </div>
            <div class="resultListCenter">
                <div class="resultListCenterItem" v-for="(item,index) in pagesList " :key="index">
                    <div class="boxStyle">{{item.technologyType}}</div>
                    <div class="boxStyle">{{item.technologyCode}}</div>
                    <div class="boxStyle">{{item.technologyOutline}}</div>
                    <div class="lookCk">查看案例</div>
                </div>

                <div class="notHaveImgBOX" v-if="notHaveImgShow">
                    <div class="notHaveImg">
                        <img src="../assets/img/zwsj.png" alt="">
                        <p class="notHaveText">暂无数据！</p>
                    </div>
                </div>

            </div>
            <div class="resultListButtom">
                <div>
                    <el-pagination
                   layout="total, prev, pager, next"
                   :total="total"
                   :page-count="pageCount"
                   :page-size="pageSizes"
                   :current-page="currentPage"
                   @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    import ui from "@/libs/ui";
    import {
        getWasteSourceSelect,
        getWasteBigTypeSelect,
        getWasteSmallTypeSelect,
        getProductBigSelect,
        getProductSmallSelect
    } from "@/api/common";
    import {
        queryPage
    } from "@/api/product"

    export default {
        name: 'NewClassification',
        props: {
            msg: String
        },
        data() {
            return {
                input: '',
                notHaveImgShow: false,
                sources:[],   //固废来源
                productBig:[],    //资源化产品大类
                pagesList:[],    //分页数据
                total: 0,
                currentPage: 1,
                pageCount: 0,
                pageSizes: 20,
                
                searchModel: {
                    searchValue: '',
                    sources: [],
                    wasteBigTypes: [],
                    wasteSmallTypes: [],
                    productBigTypes: [],
                    productSmallTypes: []
                },
               
                // wasteSource: [],
                wasteBigType: [],
                wasteSmallType: [],
                productBigType: [],
                productSmallType: []
           
         
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.queryWasteSourceSelect()
                this.queryProductBigSelect()
                this.selectData()
            },
            clickCategory (item,index){
                this.categoryIndex = index
                item.active = !item.active
                // debugger
                this.searchModel.sources.push(item.value)
                console.log(this.searchModel.sources.push(item.value),"454654654")
                this.queryWasteBigSelect()
            },
            
            // 固废大类
            clickbig(item,index){
                this.categoryIndex = index
                item.active1= !item.active1
                this.searchModel.wasteBigTypes.push(item.value)
                this.queryWasteSmallSelect()
            },
            // 固废小类
            smallCK(item,index) {
                this.categoryIndex = index
                item.active2= !item.active2
        
                this.searchModel.wasteSmallTypes.push(item.value)
                this.queryProductSmallSelect()
            },
            // 固废来源
            queryWasteSourceSelect() {
                getWasteSourceSelect().then(data => {
                    for( let i=0; i<data.length; i++){
                        data[i].active = false
                    }
                    this.sources = data
                    // console.log( this.sources,"45646")
                })
            },
            // 固废大类
            queryWasteBigSelect() {
                if (this.searchModel.length === 0) return
                getWasteBigTypeSelect(this.searchModel.sources).then(data => {
                    for( let i=0; i<data.length; i++){
                        data[i].active1 = false
                    }
                    this.wasteBigType = data
                })
            },
            // 固废小类
            queryWasteSmallSelect() {
                if (this.searchModel.wasteBigTypes.length === 0) return
                getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then(data => {
                    for( let i=0; i<data.length; i++){
                        data[i].active2 = false
                    }
                    this.wasteBigType = data
                })
            },
             //资源化产品大类
            queryProductBigSelect() {
                getProductBigSelect().then(data => {
                    this.productBig = data
                   
                })
            },
            
            
            queryProductSmallSelect() {
                if (this.searchModel.productBigTypes.length === 0) return
                getProductSmallSelect(this.searchModel.wasteSmallTypes, this.searchModel.productBigTypes).then(data => {
                    this.productSmallType = data
                })
            },
            
            
            queryTableData(searchParams, page) {
                if (!searchParams) {
                    searchParams = this.searchModel
                }
                queryPage(searchParams, page).then(data => {
                    this.pagesList = data.records
                    this.total = data.total
                    this.currentPage = data.current
                    this.pageSizes = data.size
                    this.pageCount = data.pages
                })
            },
            selectData() {
                this.queryTableData()
            },
            handleCurrentChange(val) {
                const page = {
                    pageNo: val,
                    pageSize: this.pageSizes
                }
                this.queryTableData(this.searchModel, page)
            },
            toSearch() {
                // eslint-disable-next-line no-debugger
                // debugger
                if (this.keyword === '') {
                    ui.toast(this, ui.variant.primary, '请输入搜索内容')
                    return;
                }
                if (this.$store.getters.token === '') {
                    ui.toast(this, ui.variant.primary, '请先登录账号')
                    return;
                }
                this.$router.push('/search/' + this.keyword)
            },



        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    @import "../assets/less/data.less";

    .newBox {
        height: 1700px;
    }

    .headerBox {
        width: 100%;
        height: 7%;
        text-align: center;
        border-bottom: 1px solid #DDDDDD;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;

        .headerText {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #1A1A1A;
        }
    }
    .active {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #007EFF;
        margin-left: 4%;
    }
    .active1 {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #007EFF;
        margin-left: 4%;
    }
    .active2 {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #007EFF;
        margin-left: 4%;
    }
    .centerSelect {
        width: 100%;
        height: 40%;
        margin-top: 3%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .leftSelectBox {
            width: 30%;
            height: 42%;
            border-bottom: 1px solid #DDDDDD;

            .SelectTopBox {
                width: 100%;
                height: 15%;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #1A1A1A;
            }

            .blackStyle {
                margin: 3% 0;
                width: 40px;
                height: 4px;
                background: #353535;
                border-radius: 2px;
            }

            .SelectListBox {
                width: 100%;
                height: 70%;
                margin-top: 5%;
                overflow-y: auto;

                .SelectListItem {
                    width: 100%;
                    height: 10%;
                    margin-bottom: 5%;
                    display: flex;
                    cursor: pointer;
                }

                .gouImg {
                    width: 17px;
                    height: 13px;

                    img {
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                    }
                }

                .itemText {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    // color: #007EFF;
                    // color: #333;
                    margin-left: 4%;
                }
            }

        }

        .leftSelectBoxTWO {
            width: 30%;
            height: 42%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F9F9FA;
            border: 1px solid #EEEEEE;

            .fuzzyQuerybOX {
                width: 80%;
                height: 80%;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #1A1A1A;

                .cxStyle {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #007EFF;
                }

                .CXResultBox {
                    width: 142px;
                    height: 40px;
                    margin-top: 5%;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 40px;
                    cursor: pointer;
                    background: #007EFF;
                }
            }
        }
    }

    .emptyAndInquire {
        width: 100%;
        height: 3%;
        margin-top: 1%;
        display: flex;
        justify-content: center;
        cursor: pointer;

        .qKBox {
            width: 10%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .qKImg {
                width: 18px;
                height: 18px;
                margin-right: 5%;

                img {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                }
            }

            .qkTEXT {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                margin-top: 4%;
                color: #007EFF;
            }
        }

        .InquireBOX {
            width: 11%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(270deg, #0F69DE, #0090FF);
            border-radius: 20px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FEFEFE;

            .qKImg {
                width: 18px;
                height: 19px;
                margin-right: 5%;

                img {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                }
            }

            .cXTEXT {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FEFEFE;
                margin-top: 4%;
            }
        }
    }

    .CXresult {
        width: 100%;
        height: 10%;
        margin-top: 2%;
        text-align: center;
        padding: 2% 0;
        box-sizing: border-box;

        .EnglishText {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;

        }

        .resultText {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #1A1A1A;
        }
    }

    .resultListBox {
        width: 100%;
        height: 30%;

        .resultListTop {
            width: 100%;
            height: 15%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: #F9F9FA;
            border: 1px solid #EEEEEE;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #555555;

            .boxStyle {
                width: 25%;
                text-align: center;
            }
        }

        .resultListCenter {
            width: 100%;
            height: 70%;
            overflow-y: auto;
            border: 1px solid #EEEEEE;
            background-color: #FFFFFF;

            .resultListCenterItem {
                width: 100%;
                height: 25%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                border-bottom: 1px solid #EEEEEE;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #000000;

                .lookCk {
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #007EFF;
                    cursor: pointer;
                    width: 25%;
                    text-align: center;
                }

                .boxStyle {
                    width: 25%;
                    text-align: center;
                }
            }

            .notHaveImgBOX {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;

                .notHaveImg {
                    width: 100px;
                    height: 90px;
                    text-align: center;

                    img {
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                    }

                    .notHaveText {
                        margin-top: 10%;
                    }
                }
            }
        }

        .resultListButtom {
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFFFFF;
        }
    }

    .resultListCenter::-webkit-scrollbar {
        width: 8px !important;
        height: 100% !important;
        background: #EEEEEE;
        border-radius: 3px;
    }

    .resultListCenter::-webkit-scrollbar-thumb {
        width: 8px !important;
        height: 50% !important;
        background: #007EFF;
        border-radius: 3px;
    }

    .SelectListBox::-webkit-scrollbar {
        width: 8px !important;
        height: 100% !important;
        background: #EEEEEE;
        border-radius: 3px;
    }

    .SelectListBox::-webkit-scrollbar-thumb {
        width: 8px !important;
        height: 50% !important;
        background: #007EFF;
        border-radius: 3px;
    }
</style>
